import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DealerWorkflowDTO } from '@ovex/annual-target-web-api';

import {
  handleGetDealerContract,
  shiftDealerContractWorkflow,
  handleUpdateContractAnnualTargetApproval,
  handleUpdateContractAnnualTargetDealer
} from '../../redux/modules/actions';
import { ContractStatusEnum } from '../../utils/const';

import DealerAnnualTarget from './DealerAnnualTarget';

const DealerAnnualTargetConnector = () => {
  const selectedState = useSelector(state => {
    return {
      dealerContractList: state.at.dealerContract.dealerContractList,
      error: state.at.dealerContract.error,
      fetching: state.at.dealerContract.isFetching
    };
  });

  const dispatch = useDispatch();
  const callbacks = React.useMemo(
    () => {
      return {
        handleGetDealerContract: () => dispatch(handleGetDealerContract()),
        handleApproveByDirector: (contractId) => dispatch(shiftDealerContractWorkflow(DealerWorkflowDTO.constructFromObject({ status: ContractStatusEnum.DIRECTOR_APPROVED, contractIdList: [contractId] }, null))),
        handleUpdateContractAnnualTargetDealer: (updateDTOList) => dispatch(handleUpdateContractAnnualTargetDealer(updateDTOList)),
        handleUpdateContractAnnualTargetApproval: (updateDTOList) => dispatch(handleUpdateContractAnnualTargetApproval(updateDTOList))
      };
    },
    [dispatch]
  );

  return (
    <DealerAnnualTarget
      dealerContractList={selectedState.dealerContractList}
      error={selectedState.error}
      fetching={selectedState.fetching}
      onApproveByDirector={callbacks.handleApproveByDirector}
      onGetDealerContract={callbacks.handleGetDealerContract}
      onUpdateContractAnnualTargetApproval={callbacks.handleUpdateContractAnnualTargetApproval}
      onUpdateContractAnnualTargetDealer={callbacks.handleUpdateContractAnnualTargetDealer}
    />
  );
};

export default DealerAnnualTargetConnector;