import { RestrictionTypeEnum as RestrictionTypeEnumClass } from '@ovex/pvt-web-api';

export const RestrictionTypeEnum = new RestrictionTypeEnumClass();

export const restrictionTypeOptions = [RestrictionTypeEnum.S, RestrictionTypeEnum.T]

/**
 * Helper function to generate Select options from RestrictionType enum
 * @param lsiRestrictionType lsi labels of restriction type
 */
export const generateRestrictionTypeOptions = (lsiRestrictionType) => {
  return restrictionTypeOptions
    .map((restrictionType) => {
      return {
        value: restrictionType,
        content: lsiRestrictionType[restrictionType]
      };
    });
};
