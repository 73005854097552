import PropTypes from 'prop-types';
import React from 'react';

import { Button, ButtonBar } from '../../../../common/components';
import { LsiContext } from '../../../../common/contexts';
import { GetDealerContractsFunctionalityContext } from '../../../privilege/context';
import ContractSelectItem from '../utils/ContractSelectItem';

const propTypes = {
  contractSelectItemSelected: PropTypes.instanceOf(ContractSelectItem),
  onApprove: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onEnableEditApprovalNumber: PropTypes.func,
  onEnableEditDealerNumber: PropTypes.func,
  onReload: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  onShowContractPreview: PropTypes.func
};

const defaultProps = {
  contractSelectItemSelected: null,
  editable: false,
  onApprove: undefined,
  onCancelEdit: undefined,
  onEnableEditApprovalNumber: undefined,
  onEnableEditDealerNumber: undefined,
  onSave: undefined,
  onShowContractPreview: undefined
};

const getDealerContractsFunctionalityContext = new GetDealerContractsFunctionalityContext();

const DealerAnnualTargetButtonBar = React.memo((props) => {
  const lsi = React.useContext(LsiContext);

  const _renderReadButtons = () => {
    const privilegeFnCtxObject = props.contractSelectItemSelected && props.contractSelectItemSelected.privilegeFunctionalityContextObject;
    return (
      <React.Fragment>
        { props.onEnableEditDealerNumber &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.EDIT_TENTATIVE')}
            key='ovex-DealerAnnualTargetButtonBar-btn-edit-dealerNumber'
            onClick={props.onEnableEditDealerNumber}
            type={Button.type.PRIMARY}
          />
        }
        { props.onEnableEditApprovalNumber &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.EDIT_BINDING')}
            key='ovex-DealerAnnualTargetButtonBar-btn-edit-approvalNumber'
            onClick={props.onEnableEditApprovalNumber}
            type={Button.type.PRIMARY}
          />
        }
        { props.onApprove &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.APPROVE')}
            key='ovex-DealerAnnualTargetButtonBar-btn-approve'
            onClick={props.onApprove}
            privilegeFunctionalityContext={privilegeFnCtxObject && privilegeFnCtxObject.shiftDealerContractWorkflowFnCtx}
            type={Button.type.PRIMARY}
          />
        }
        { props.onShowContractPreview &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.DETAIL')}
            key='ovex-DealerAnnualTargetButtonBar-btn-detail'
            onClick={props.onShowContractPreview}
            privilegeFunctionalityContext={privilegeFnCtxObject && privilegeFnCtxObject.getDealerContractPreviewFnCtx}
            type={Button.type.SECONDARY}
          />
        }
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.RELOAD')}
          key='ovex-DealerAnnualTargetButtonBar-btn-reload'
          onClick={props.onReload}
          privilegeFunctionalityContext={getDealerContractsFunctionalityContext}
          type={Button.type.SECONDARY}
        />
      </React.Fragment>
    );
  };

  const _renderEditButtons = () => {
    return (
      <React.Fragment>
        { props.onCancelEdit &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.CANCEL')}
            key='ovex-DealerAnnualTargetButtonBar-btn-cancel'
            onClick={props.onCancelEdit}
            type={Button.type.SECONDARY}
          />
        }
        { props.onSave &&
          <Button
            content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.SAVE')}
            key='ovex-DealerAnnualTargetButtonBar-btn-save'
            onClick={props.onSave}
            type={Button.type.PRIMARY}
          />
        }
        <Button
          content={lsi.getLSIItem('ANNUAL_TARGET.BUTTON.RELOAD')}
          disabled
          key='ovex-DealerAnnualTargetButtonBar-btn-reload'
          type={Button.type.SECONDARY}
        />
      </React.Fragment>
    );
  };

  let buttons;
  if (props.onSave) {
    buttons = _renderEditButtons();
  } else {
    buttons = _renderReadButtons();
  }

  return (
    <ButtonBar className='ovex-DealerAnnualTargetButtonBar'>
      {buttons}
    </ButtonBar>
  );
});

DealerAnnualTargetButtonBar.propTypes = propTypes;
DealerAnnualTargetButtonBar.defaultProps = defaultProps;

export default DealerAnnualTargetButtonBar;
