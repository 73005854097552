import PropTypes from 'prop-types';
import React from 'react';
import {
  ContractATRecommendedWrapperUpdateDTO,
  ContractWorkflowResponseDTO,
  DealerContractFilterDataDTO,
  DealerWorkflowDTO
} from '@ovex/annual-target-web-api';

import { FormsContextSection } from '../../../common/components/forms';
import { Page } from '../../../common/components';
import { LsiContext, NewTabContext } from '../../../common/contexts';
import { useAlertBusOvex, useConfirmModal, usePrivilege } from '../../../common/hooks';
import { AlertTypeEnum } from '../../../common/objects';
import { ContractStatusEnum } from '../../utils/const';
import generatePath from '../ContractPreview/generatePath';
import useShowCommissionListWithParamsForImporter
  from '../CommissionList/hooks/useShowCommissionListWithParamsForImporter';

import DealerContractsFilter from './DealerContractsFilter/DealerContractsFilter';
import DealerContractsTable from './DealerContractsTable/DealerContractsTable';
import DealerContractRow from './DealerContractsTable/DealerContractRow';
import { isDisabledEditable, isHiddenRCP, isHiddenROC } from './DealerContracts.helpers';

import './DealerContracts.scss';

const propTypes = {
  canShiftWorkflow: PropTypes.bool,
  dealerContractRows: PropTypes.arrayOf(PropTypes.instanceOf(DealerContractRow)),
  filterData: PropTypes.instanceOf(DealerContractFilterDataDTO),
  getDealerContracts: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  isFetching: PropTypes.bool,
  onShiftDealerContractWorkflow: PropTypes.func.isRequired,
  onUpdateContractAnnualTargetRecommended: PropTypes.func,
  valuesFilter: PropTypes.object,
  workflowResponse: PropTypes.instanceOf(ContractWorkflowResponseDTO)
};

const defaultProps = {
  canShiftWorkflow: false,
  dealerContractRows: null,
  filterData: null,
  isEditable: false,
  isFetching: true,
  onUpdateContractAnnualTargetRecommended: undefined,
  valuesFilter: null,
  workflowResponse: null
};

const DealerContracts = React.memo((props) => {
  const lsi = React.useContext(LsiContext);
  const openInNewTab = React.useContext(NewTabContext);
  const { handleAddAlertSimple } = useAlertBusOvex();

  const { handleIsPrivileged } = usePrivilege();

  const valuesFilterSubmitted = props.valuesFilter || DealerContractsFilter.valuesDefault;
  const getDealerContracts = props.getDealerContracts;
  const handleReloadDealerContracts = React.useCallback((filterValues) => {
    const asyncCall = async () => {
      try {
        await getDealerContracts(filterValues);
      } catch (e) {
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.GET_DEALER_CONTRACTS_FAILURE', AlertTypeEnum.WARNING);
      }
    };
    asyncCall().then();
  }, [getDealerContracts, handleAddAlertSimple]);
  React.useEffect(
    () => handleReloadDealerContracts(DealerContractsFilter.valuesDefault),
    [handleReloadDealerContracts]
  );

  const onUpdateContractAnnualTargetRecommended = props.onUpdateContractAnnualTargetRecommended;
  const handleUpdateDealerContracts = React.useCallback(
    async (modelGroupAnnualTargetChanges, periodAnnualTargetSalesChanges) => {
      try {
        const updateContractAnnualTargetResponse = onUpdateContractAnnualTargetRecommended(ContractATRecommendedWrapperUpdateDTO.constructFromObject({
          atList: modelGroupAnnualTargetChanges,
          atsList: periodAnnualTargetSalesChanges
        }));
        await updateContractAnnualTargetResponse;

        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACTS_SUCCESS');
        handleReloadDealerContracts(valuesFilterSubmitted);

        return Promise.resolve(updateContractAnnualTargetResponse);
      } catch (e) {
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.UPDATE_DEALER_CONTRACTS_FAILURE', AlertTypeEnum.WARNING);
        return Promise.reject();
      }
    },
    [onUpdateContractAnnualTargetRecommended, handleReloadDealerContracts, handleAddAlertSimple, valuesFilterSubmitted]
  );

  const onShiftDealerContractWorkflow = props.onShiftDealerContractWorkflow;
  const handleShiftDealerContractWorkflow = React.useCallback((formData) => {
    const asyncCall = async () => {
      try {
        await onShiftDealerContractWorkflow(DealerWorkflowDTO.constructFromObject({
          status: formData.nextStatus,
          contractIdList: formData.selectedContractList.map(contract => contract.id)
        }));
        handleReloadDealerContracts(valuesFilterSubmitted);
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_CONTRACT_WORKFLOW_SUCCESS');
      } catch (e) {
        handleAddAlertSimple('ANNUAL_TARGET.ERROR_MESSAGE.SHIFT_CONTRACT_WORKFLOW_FAILURE', AlertTypeEnum.WARNING);
      }
    };
    asyncCall().then();
  }, [onShiftDealerContractWorkflow, handleReloadDealerContracts, handleAddAlertSimple, valuesFilterSubmitted]);

  const handleShowContractPreview = React.useCallback((contractId) => {
    openInNewTab(generatePath(contractId));
  }, [openInNewTab]);

  const [ renderConfirmShiftModal, handleShowConfirmModal ] = useConfirmModal(handleShiftDealerContractWorkflow);
  const handleConfirmShiftDealerContractWorkflow = React.useCallback(
    (formData) => {
      let content = null;
      if (formData.nextStatus === ContractStatusEnum.AREA_ADVISOR_APPROVED) {
        content = lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.DEALER_CONTRACTS_APPROVAL');
      } else if (formData.nextStatus === ContractStatusEnum.AA_MANAGER_APPROVED) {
        content = lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.DEALER_CONTRACTS_APPROVAL');
      } else if (formData.nextStatus === ContractStatusEnum.REJECTED) {
        content = lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.DEALER_CONTRACTS_REJECTION');
      } else if (formData.nextStatus === ContractStatusEnum.CREATED) {
        content = lsi.getLSIItem('ANNUAL_TARGET.CONFIRM_MESSAGE.DEALER_CONTRACTS_RETURN');
      }
      handleShowConfirmModal && handleShowConfirmModal({
        confirmModalProps: { content: content },
        ...formData
      });
    },
    [lsi, handleShowConfirmModal]
  );

  const hiddenROC = isHiddenROC(valuesFilterSubmitted);
  const hiddenRCP = isHiddenRCP(valuesFilterSubmitted);
  const disabledEditable = isDisabledEditable(valuesFilterSubmitted);

  const handleShowCommissionListWithParams = useShowCommissionListWithParamsForImporter();

  return (
    <Page
      className='ovex-DealerContracts'
      header={lsi.getLSIItem('ANNUAL_TARGET.PAGE_TITLE.ANNUAL_TARGET_IMPORTER')}
      loading={props.isFetching}
    >
      <FormsContextSection>
        <DealerContractsFilter
          filterData={props.filterData}
          onSubmit={handleReloadDealerContracts}
          valuesFilterSubmitted={valuesFilterSubmitted}
        />
        <DealerContractsTable
          dealerContractRows={props.dealerContractRows}
          disabledEditable={disabledEditable}
          hiddenRCP={hiddenRCP}
          hiddenROC={hiddenROC}
          onIsPrivileged={handleIsPrivileged}
          onShiftDealerContractWorkflow={props.canShiftWorkflow ? handleConfirmShiftDealerContractWorkflow : undefined}
          onShowCommissionListWithParams={handleShowCommissionListWithParams}
          onShowContractPreview={handleShowContractPreview}
          onUpdateContractRecommendedNumber={props.isEditable ? handleUpdateDealerContracts : undefined}
        />
      </FormsContextSection>
      {renderConfirmShiftModal()}
    </Page>
  );
});

DealerContracts.propTypes = propTypes;
DealerContracts.defaultProps = defaultProps;

export default DealerContracts;
