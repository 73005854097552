import { combineReducers } from 'redux';

import { moduleReducerName } from '../../config/moduleConfig';

import productionCorridor from './productionCorridor/productionCorridor';
import balance from './balance/balance';
import restrictions from './restrictions/restrictions';
import swapCommissionPvt from './swapCommissionPvt/swap-commission-pvt';
//
import userInfo from './about/userInfo/userInfo';
//
import privilege from './privilege/privilege/privilege';
//
import initialization from './initialization/initialization';

const moduleReducers = {
  propName: moduleReducerName,
  combineReducers: combineReducers({
    productionCorridor: productionCorridor,
    balance: balance,
    restrictions: restrictions,
    swapCommissionPvt: swapCommissionPvt,

    userInfo: userInfo,

    privilege: privilege,

    initialization: initialization
  })
};

export default moduleReducers;