import React from 'react';
import { useDispatch } from 'react-redux';

import { usePrivilege } from '../../../common/hooks';
import moduleContextData from '../../config/moduleContextData';
import {
  BGetBalanceFunctionalityContext,
  BUpdateBalanceFunctionalityContext,
  PCGetProductionCorridorListFunctionalityContext,
  PCUpdateProductionCorridorBulkFunctionalityContext,
  QRGetRestrictionListFunctionalityContext,
  QRUpdateRestrictionBulkFunctionalityContext,
  SCPGetCommissionsSwapDealerFunctionalityContext,
  SCPGetCommissionsSwapImporterFunctionalityContext,
  SCPSwapCommissionsPvtDealerFunctionalityContext,
  SCPSwapCommissionsPvtImporterFunctionalityContext
} from '../../privilege/context';
import { handlePrivilegeInitializationFailed, handlePrivilegeInitialized } from '../../redux/modules/actions';

const propTypes = {};
const defaultProps = {};

const PvtInitialization = () => {
  const dispatch = useDispatch();

  const { handlePrivilegeRequest } = usePrivilege(moduleContextData);

  React.useEffect(
    () => {
      const functionalityContextList = [
        new PCGetProductionCorridorListFunctionalityContext(),
        new QRGetRestrictionListFunctionalityContext(),
        new PCUpdateProductionCorridorBulkFunctionalityContext(),
        new QRUpdateRestrictionBulkFunctionalityContext(),
        new SCPGetCommissionsSwapImporterFunctionalityContext(),
        new SCPGetCommissionsSwapDealerFunctionalityContext(),
        new SCPSwapCommissionsPvtDealerFunctionalityContext(),
        new SCPSwapCommissionsPvtImporterFunctionalityContext(),
        new BGetBalanceFunctionalityContext(),
        new BUpdateBalanceFunctionalityContext()
      ];

      const asyncCall = async () => {
        try {
          await handlePrivilegeRequest(functionalityContextList);
          dispatch(handlePrivilegeInitialized());
        } catch (e) {
          dispatch(handlePrivilegeInitializationFailed());
        }
      };
      asyncCall().then();
    },
    [handlePrivilegeRequest, dispatch]
  );

  return null;
};

PvtInitialization.propTypes = propTypes;
PvtInitialization.defaultProps = defaultProps;

export default PvtInitialization;